"use client";

import { Fragment } from "react";
import { FC } from "react";
import DatePicker from "react-datepicker";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import ClearDataButton from "../ClearDataButton";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomHeaderOneMonth from "components/DatePickerCustomHeaderOneMonth";
import { useData } from "data/data-provider";
import { changeDate } from "utils/changeDate";

export interface RentalCarDatesRangeInputProps {
  className?: string;
  fieldClassName?: string;
  hasButtonSubmit?: boolean;
  showSingleMonth?: boolean;
}

const RentalCarDatesRangeInput: FC<RentalCarDatesRangeInputProps> = ({
  className = "",
  fieldClassName = "[ nc-hero-field-padding ]",
  showSingleMonth,
}) => {
  const {
    startDate,
    startHours,
    startMinutes,
    startPeriod,
    endDate,
    endHours,
    endMinutes,
    endPeriod,
    ourVehicles,
    setStartDate,
    setEndDate,
  } = useData();

  const minTripDuration = ourVehicles[0]?.min_trip_duration || 1;
  const maxTripDuration = ourVehicles[0]?.max_trip_duration || 30;

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    changeDate(
      dates,
      startHours,
      startMinutes,
      startPeriod,
      endHours,
      endMinutes,
      endPeriod,
      setStartDate,
      setEndDate
    );
  };

  const renderInput = () => (
    <>
      <div className="text-neutral-300 dark:text-neutral-400">
        <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
      </div>
      <div className="flex-grow text-left">
        <span className="block xl:text-lg font-semibold">
          {startDate?.toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
          }) || "Add dates"}
          {endDate
            ? " - " +
              endDate?.toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
              })
            : ""}
        </span>
        <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
          {"Pick up - Drop off"}
        </span>
      </div>
    </>
  );

  return (
    <>
      <Popover
        className={`RentalCarDatesRangeInput z-20 relative flex ${className}`}
      >
        {({ open }) => (
          <>
            <div
              className={`flex-1 z-10 flex items-center focus:outline-none ${
                open ? "nc-hero-field-focused" : ""
              }`}
            >
              <Popover.Button
                className={`flex-1 z-10 p-4 flex relative ${fieldClassName} items-center space-x-3 focus:outline-none `}
                onClickCapture={() => document.querySelector("html")?.click()}
              >
                {renderInput()}

                {startDate && open && (
                  <ClearDataButton
                    onClick={() => {
                      const today = new Date();
                      const endDate = new Date(today); // Clone today's date
                      endDate.setDate(today.getDate() + minTripDuration - 1); // Add minTripDuration days
                      onChangeDate([today, endDate]);
                    }}
                  />
                )}
              </Popover.Button>
            </div>

            {open && (
              <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 bg-white dark:bg-[#140B0B]"></div>
            )}

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-40 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-black p-8">
                  <DatePicker
                    selected={startDate}
                    onChange={onChangeDate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    monthsShown={showSingleMonth ? 1 : 2}
                    showPopperArrow={false}
                    inline
                    minDate={new Date()} // Allow selection only from today onward
                    filterDate={(date) => {
                      const today = new Date();
                      today.setHours(0, 0, 0, 0); // Reset today's time to midnight

                      if (startDate) {
                        const start = new Date(startDate);
                        start.setHours(0, 0, 0, 0); // Reset startDate time to midnight

                        const diffInDays =
                          (date.getTime() - start.getTime()) /
                          (1000 * 60 * 60 * 24);

                        if (date >= start) {
                          return (
                            diffInDays >= minTripDuration - 1 &&
                            diffInDays <= maxTripDuration - 1
                          );
                        }
                      }

                      return date >= today; // Allow selection starting from today
                    }}
                    renderCustomHeader={(p) =>
                      showSingleMonth ? (
                        <DatePickerCustomHeaderOneMonth {...p} />
                      ) : (
                        <DatePickerCustomHeaderTwoMonth {...p} />
                      )
                    }
                    renderDayContents={(day, date) => (
                      <DatePickerCustomDay dayOfMonth={day} date={date} />
                    )}
                  />
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

export default RentalCarDatesRangeInput;
