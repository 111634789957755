import rightImg from "images/aboutus.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";

import { Helmet } from "react-helmet";

import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";

import Features from "components/Feature/Feature";
import PageContact from "containers/PageContact/PageContact";
import SectionStatistic from "./SectionStatistic";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative bg-black ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || Car rental</title>
      </Helmet>

      <div className="container py-12 ">
        <SectionHero
          rightImg={rightImg}
          heading="The best rental service for all-wheel lovers."
          btnText=""
          subHeading={globalJson?.about_us_para}
        />
      </div>
      <div className="px-12 bg-[#121212]">
        <div className="py-12">
          <SectionStatistic />
        </div>
      </div>
    </div>
  );
};

export default PageAbout;
