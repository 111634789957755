import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import L from "leaflet";
import toast from "react-hot-toast";
import { globalJson } from "global/global_json";
import { ContactFun } from "api/profile/profile";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: globalJson.address,
  },
  {
    title: "💌 EMAIL",
    desc: globalJson.email,
  },
  {
    title: "☎ PHONE",
    desc: globalJson.phone,
  },
];

const ContactSection: FC<PageContactProps> = ({ className = "" }) => {
  const [getEmail, setEmail] = useState("");
  const [newName, setNewName] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const sendEmail = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!getEmail.trim() || !emailRegex.test(getEmail)) {
      toast.error("Please enter a valid email address");
      setLoading(false);
      return;
    }

    if (getEmail.length === 0 || newName.length === 0 || message.length === 0) {
      toast.error("Fields are required!");
      setLoading(false);
      return;
    }

    // Create the payload with name, email, and message
    const payload = {
      name: newName,
      email: getEmail,
      message: message,
    };

    const result = await ContactFun(payload);

    if (result.error) {
      toast.error(result.error);
    } else {
      toast.success("Sent successfully!");
      setEmail("");
      setNewName("");
      setMessage("");
    }

    setLoading(false);
  };

  const customIcon = L.icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/684/684908.png", // URL to your custom icon
    iconSize: [32, 32], // size of the icon
    iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -32], // point from which the popup should open relative to the iconAnchor
  });

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <div className="" style={{ minHeight: "70vh" }}>
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-medium text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
   
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div
              style={{
                width: "100%",
                height: "400px",
                borderRadius: "none",
                overflow: "hidden",
                marginTop: "12px",
              }}
            >
              <MapContainer
                center={[37.26298983551826, -121.93396802883551]}
                zoom={14}
                style={{ width: "100%", height: "100%" }}
              >
                <TileLayer
                  url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.carto.com/attributions">CARTO</a> contributors'
                />
                <Marker
                  position={[37.26298983551826, -121.93396802883551]}
                  icon={customIcon}
                >
                  <Popup>Your selected location.</Popup>
                </Marker>
              </MapContainer>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={sendEmail}>
                <label className="block">
                  <Label>Full name</Label>
                  <Input
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    value={newName}
                    onChange={(e) => {
                      setNewName(e.target.value);
                    }}
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>

                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    value={getEmail}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>

                  <Textarea
                    className="mt-1"
                    rows={6}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </label>
                <div>
                  <ButtonPrimary type="submit" className="min-w-[200px]">
                    {loading ? (
                      <div className="flex justify-center items-center col-span-full">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                      </div>
                    ) : (
                      "Send Message"
                    )}
                  </ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
     
      </div>

      {/* OTHER SECTIONS */}
      {/* <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div> */}
    </div>
  );
};

export default ContactSection;
