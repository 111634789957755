import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const HomeAboutSection: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className} mb-20 lg:mb-36`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row my-auto lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full lg:max-w-xl space-y-5 mx-12 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-[#E5E7EB]">
            {heading}
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-[#FFFFFFE5]">
            {subHeading}
          </span>
          {!!btnText && <ButtonPrimary href="/signup">{btnText}</ButtonPrimary>}
        </div>
        <div className="flex-grow">
          <img className="w-full" src={rightImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HomeAboutSection;
