import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/travelhero2.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import MobileSearch from "components/MobileSearchForm/MobileSearch";

export interface SectionHero3Props {
  className?: string;
}

const SectionHero3: FC<SectionHero3Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero3 relative ${className}`}
      data-nc-id="SectionHero3"
    >
      <div className="absolute inset-0 flex items-center justify-center z-10">
        <div className="hidden lg:block w-full max-w-full">
          <div className="flex justify-center">
            <h2 className="text-6xl font-bold">Joyrides</h2>
          </div>
          <div className="flex justify-center mt-4 text-2xl max-w-xl mx-auto">
            <span className="text-center">
              No Hassles, Just Happy Miles: Your Utah Car Rental Dream Starts
              Here
            </span>
          </div>
          <HeroSearchForm />
        </div>
        <div className="block lg:hidden z-50 w-full max-w-full mt-36 px-4 md:px-24">
        <div className="flex flex-row justify-center">
            <h2 className="text-2xl md:text-4xl font-bold">Joyrides Rental</h2>
          </div>
          <div className="flex justify-center mt-4 text-lg max-w-lg mx-auto">
            <span className="text-center">
              No Hassles, Just Happy Miles: Your Utah Car Rental Dream Starts
              Here
            </span>
          </div>
        <MobileSearch />
      </div>
      </div>
      <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8">
        <img
          className="absolute inset-0 object-cover"
          src={imagePng}
          alt="hero"
        />
      </div>
    </div>
  );
};

export default SectionHero3;
