function Cancellation({ terms }: any) {
  return (
    <div className="mt-6 rounded-lg border bg-white dark:bg-black p-4">
      <div className="mb-8">
        <h1 className="text-lg font-bold text-[#00283E] dark:text-white">Cancellation</h1>
        <p className="max-96 text-sm text-[#86909C]">
          {/* Please note the following booking Terms and Conditions apply to
          bookings placed directly on the {companyData?.name} website. */}
        </p>
      </div>
      <div className="col-span-12 @container">
        <div className="flex flex-col gap-y-4">
          {terms?.map((item: any, i: number) => {
            return (
              <div className="rounded-lg border border-muted" key={i}>
                <div className="header rounded-lg bg-[#f7f7f7] dark:bg-black px-5 py-3">
                  <h4 className="text-lg">
                    {i + 1}. {item?.title}
                  </h4>
                </div>
                <div className="header border-t border-muted p-5 w-full">
                  <div className="grid grid-cols-1 gap-3 rounded-lg w-full">
                    <div
                  className="prose prose-sm text-gray-500 dark:text-white break-words"
                      style={{ maxWidth: "100%" }} // Manually remove the max width
                      dangerouslySetInnerHTML={{ __html: item?.content }}
                    />
                  </div>
                </div>
                {item?.content_picture === null ||
                  item?.content_picture === "" ||
                  item?.content_picture === "null" ? (
                    ""
                  ) : (
                  <div className="header border-t border-muted p-5">
                    <div className="grid grid-cols-1 gap-3 rounded-lg @md:grid-cols-1">
                      <img
                        alt="terms and conditions image"
                        src={`https://fleet-management-images-upload-be.s3.amazonaws.com/${item?.content_picture}`}
                        height={660}
                        width={600}
                        className="w-full rounded-lg"
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Cancellation;
