import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import toast from "react-hot-toast";
import L from "leaflet";
import { ContactFun } from "api/profile/profile";
import { globalJson } from "global/global_json";

export interface PageContactProps {
  className?: string;
}
export interface ContactInfo {
  title: string;
  desc: string | string[]; // Support for multiple lines of text
}
const contactInfo: ContactInfo[] = [
  {
    title: "Office Address",
    desc: globalJson.address,
  },
  {
    title: "Email Address",
    desc: globalJson.email, // Multiple lines
  },
  {
    title: "Phone Number",
    desc: globalJson.phone, // Multiple lines
  },
];

const customIcon = L.icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/684/684908.png",
  iconSize: [32, 32],
});

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [getEmail, setEmail] = useState("");
  const [newName, setNewName] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const sendEmail = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!getEmail.trim() || !emailRegex.test(getEmail)) {
      toast.error("Please enter a valid email address");
      setLoading(false);
      return;
    }

    if (getEmail.length === 0 || newName.length === 0 || message.length === 0) {
      toast.error("Fields are required!");
      setLoading(false);
      return;
    }

    // Create the payload with name, email, and message
    const payload = {
      name: newName,
      email: getEmail,
      message: message,
    };

    const result = await ContactFun(payload);

    if (result.error) {
      toast.error(result.error);
    } else {
      toast.success("Sent successfully!");
      setEmail("");
      setNewName("");
      setMessage("");
    }

    setLoading(false);
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact || Car rental</title>
      </Helmet>
      <div className="mb-24 my-16 lg:mb-32">
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-2xl mx-auto text-white">
              {/* Section Title */}
              <h2 className="text-4xl font-bold mb-8">Get In Touch</h2>

              {/* Contact Information */}
              <div className="space-y-8 py-12">
                {contactInfo.map((item, index) => (
                  <div key={index} className="flex items-start space-x-4">
                    {/* Title */}
                    <h3 className="font-semibold text-lg flex-shrink-0 min-w-[150px]">
                      {item.title}
                    </h3>

                    {/* Colon */}
                    <span className="font-semibold text-lg">:</span>

                    {/* Description */}
                    <div className="text-neutral-400">
                      {Array.isArray(item.desc) ? (
                        // Render multiple lines if desc is an array
                        item.desc.map((line, i) => (
                          <p key={i} className="mb-1">
                            {line}
                          </p>
                        ))
                      ) : (
                        <p>{item.desc}</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h2 className="text-4xl font-bold mb-8">Contact</h2>
              <form className="grid grid-cols-1 gap-6" onSubmit={sendEmail}>
                <label className="block">
                  <Label>Full name</Label>
                  <Input
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    value={newName}
                    onChange={(e) => {
                      setNewName(e.target.value);
                    }}
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>

                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    value={getEmail}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>

                  <Textarea
                    className="mt-1"
                    rows={6}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </label>
                <div>
                  <ButtonPrimary type="submit" className="min-w-[200px]">
                    {loading ? (
                      <div className="flex justify-center items-center col-span-full">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                      </div>
                    ) : (
                      "Send Message"
                    )}
                  </ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100vw", // Full screen width
          height: "400px", // Full screen height
          overflow: "hidden",
        }}
      >
        <MapContainer
          center={[37.26298983551826, -121.93396802883551]} // Center of the map
          zoom={16}
          style={{ width: "100%", height: "100%" }}
        >
          <TileLayer
            url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.carto.com/attributions">CARTO</a> contributors'
          />
          <Marker
            position={[37.26298983551826, -121.93396802883551]} // Marker position
            icon={customIcon}
          >
            <Popup>Your selected location.</Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
};

export default PageContact;
