import rightImg from "images/about-hero-right.png";
import readytodrive from "images/readytodrive.png";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHero3 from "components/SectionHero/SectionHero3";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import HomeAboutSection from "containers/PageAbout/HomeAboutSection";
import GoPlacesWith from "components/GoPlacesWith/GoPlacesWith";
import ReadytoDrive from "containers/PageAbout/ReadytoDrive";
import { useEffect } from "react";
import WhyLevel305 from "components/why305/why305";
import bgImage from "images/bg-testimonial.png";
import ContactSection from "components/ContactSection/ContactSection";

function PageHome3() {
  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
  }, []);
  return (
    <div className="nc-PageHome3 relative overflow-hidden bg-black">
      <div>
        <SectionHero3 />
      </div>
      <div className="container relative py-16 mt-28 md:mt-16 lg:mt-0">
        <SectionGridFeaturePlaces />
      </div>
      <div className="py-16 px-12 bg-[#EDAB17]">
        <HomeAboutSection
          rightImg={rightImg}
          heading="Welcome to Joyrides"
          btnText=""
          subHeading="Jpy Ride, established in 2018, envisions providing an affordable, community-based alternative to major car rental companies, often focused on business and tourist clientele. With multiple locations in Milwaukee, WI, and beyond, our commitment revolves around prioritizing safety, productivity, and enjoyment for both business and leisure trips. We take pride in delivering exceptional service and flexibility to meet the diverse needs of our valued customers."
        />
      </div>
      <div className="p-12 lg:px-24">
        <div className="py-16">
          <GoPlacesWith />
          <WhyLevel305 />
        </div>
      </div>

      <div
        className="relative py-16 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <SectionClientSay uniqueClassName="PageAbout_" />
      </div>
      <div className="container py-16">
        <ContactSection />
      </div>
      <div className="bg-[#EDAB17] ">
        <div className="container relative py-16 ">
          <ReadytoDrive
            rightImg={readytodrive}
            heading="Book your ride today!"
            btnText=""
            subHeading="Ready to hit the road? Booking with Joy Ride Rental is fast and simple. Browse our fleet online, choose your vehicle, and reserve it within minutes. For personalized assistance, feel free to contact our team directly."
          />
        </div>
      </div>
    </div>
  );
}

export default PageHome3;
