import clientSay1 from "images/client1.png";
import clientSay4 from "images/client2.png";
import clientSay5 from "images/client3.png";

export const globalJson = {

  prod_company_id: 81,
  dev_company_id: 81,
  
  // apiUtil auth header key
  authToken: "Bearer 172|AZQ2gJcOgxmowGIzePF3L5hJIfSgRi7X27mPoaQi",
  companyId: "3063d856-c76c-4882-bf50-6a535013f9a0",
  stripePublishableKey:
    "pk_live_51PknK0Dk83oWStcOt27N4cgUZUXwnzlyjnuUpz1foWOQ33IEvit9CVEzvw53wBdd5SWiBztsJtNiNfzee0AvWcme00njamw1xG",
  mapKey: "AIzaSyDKEntg7JydrxI7zr2tQL5OYcS5M-er-bc",
  residenceStateCode: "FL",

  //email confirmation admin token
  adminEmailToken:
    "cLmqPYGTMeYtTJgdzu7uhW42UtGcjORC9Xy9pW9NXPG4S2uLM9IXVRE781oZQInzIHMFUyOiajg3ewVfB4hI2JihyzvrEEYIj3vQliiGtX7uebDeLPp3ikjp9B9JDEVi",
  websiteLink: "https://Joyridesrentals.1now.app",
  sender: "driven-enterprizes",
  ccEmail: "esgatecameron0@gmail.com",

  // apiUtil sendNewsLetterEmail
  util_serviceId: "service_4i6uuad",
  util_templateId: "template_l6ked1j",
  util_userId: "Ncs-xQcOJaEzTkMSO",
  util_privateKey: "MCbzplLBG7cYi3X3QOIui",

  // apiUtil getBonzahToken
  client_secret_dev:
    "JfZjg9RCcwl2kuQbZ5KpmbTtF/m+p2kNHIwWAPnFRAoiQy9JVssr8GtjQnDO60TgAOTKhKWqa0nN0Dxu8sAwonBmi/DR087qFmQOPvPa9JC0hZdame+pj6x7mr4WlQvkvTE8Nw==",
  client_id_dev:
    "e99ee0d2-fda4-4be4-bcd2-3ca4ce51417c-20240806213162-vendor@bonzah.com",
  client_secret_prod:
    "0MTVzgTVu3fb3x4aA05IzUwEaareqKptZ+uGCdY4B5yvN8Pb2VszfhJqVSgPxiKwn8bPO+99IJk3SGQs4SA/s6AlVS31vN07iveo+dDI7lXOEgw4uX1SKoUehL7nuiM8nKYhSg==",
  client_id_prod:
    "54d97e9b-fadf-449d-a44c-44cdc4ec92af-20240806258244-vendor@bonzah.com", 

  agreement_key:
    "https://firebasestorage.googleapis.com/v0/b/driven-enterprices.appspot.com/o/agreements%2F",
  bookingId_key: ".pdf?alt=media&token=22bc5683-da60-4a85-9e54-8c353cce8051",

  //   bg_config
  firebaseConfig: {
    apiKey: "AIzaSyD_PbtkehEe75J4X7KtVSNp8EHKjy5fjXM",
    authDomain: "driven-enterprices.firebaseapp.com",
    projectId: "driven-enterprices",
    storageBucket: "driven-enterprices.appspot.com",
    messagingSenderId: "77924991211",
    appId: "1:77924991211:web:bdacedb3172af5e8dbbeae",
    measurementId: "G-DYMM3037EP",
  },

  about_us_para:
    "We are committed to providing high quality vehicles at an affordable price with high-end customer service and the most advanced technological features. ",

  clientSays_arr: [
    {
      id: 1,
      clientName: "Bhagath G",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "Great, clean car! Jonathan is super flexible about changes, and very easy to communicate with.",
      img: clientSay1,
    },
    {
      id: 2,
      clientName: "Vik S.",
      clientAddress: "LondDodge Grand Caravan 2019",
      content:
        "Absolutely amazing!",
      img: clientSay4,
    },
    {
      id: 3,
      clientName: "Victor R.",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "Very Comfortable roomy and smooth ride. Was very Happy with the Lincoln Navigator!",
      img: clientSay5,
    },
  ],

  //social links
  facebook: "https://www.facebook.com",
  instagram: "https://www.instagram.com",

  //contact
  address: "2059 Camden Ave, #232, San Jose, CA 95124",
  addressLink:
    "https://www.google.com/maps/place/4830+W+Kennedy+Blvd,+Tampa,+FL+33609,+USA/data=!4m2!3m1!1s0x88c2c2e4b61fbe87:0x7fa31f86943d0895?sa=X&ved=1t:242&ictx=111",
  phone: "408-660-0950",
  email: "jinlieuof@gmail.com",
};

export const scriptHubSpot = document.createElement("script");
scriptHubSpot.src = "//js-na1.hs-scripts.com/46980604.js";
scriptHubSpot.async = true;
scriptHubSpot.defer = true;
scriptHubSpot.id = "hs-script-loader";
